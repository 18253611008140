import styled from 'styled-components';
import { message } from 'antd';
import { useEffect } from 'react';
import { Button, Paragraph } from '../atoms';
import { colors } from '../../styles/colors';
import { trackAmplitude } from '../../utils/amplitude';
import Logo from '../../assets/images/logo.png';
import ArtworkTop from '../../assets/images/artwork-thankyou-t.png';
import ArtworkBottom from '../../assets/images/artwork-thankyou-b.png';

const ConnectInviteWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  background-color: ${colors.white};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .artwork-t {
    position: absolute;
    width: 143.35px;
    height: 96px;
    top: 20px;
    left: 20px;
    z-index: 1;
  }

  .artwork-b {
    position: absolute;
    width: 133px;
    height: 133px;
    bottom: 20px;
    right: 20px;
    z-index: 1;
  }
  .inner_wrapper {
    padding: 0px 20px;
  }

  .title {
    font-size: 35px;
    line-height: 38px;
    font-weight: 500;
    color: ${colors.black};
    margin-bottom: 30px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: ${colors.black};
    margin-bottom: 48px;
  }

  .logo {
    position: relative;
    width: 150px;
    height: 40.75px;
    margin-bottom: 40px;
    z-index: 2;
  }

  .buttons_wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    div {
      margin: 10px 0px;
    }
  }

  @media screen and (min-width: 500px) {
    max-width: 100vw;
    min-height: 100vh;

    .logo {
      margin-bottom: 50px;
    }

    .buttons_wrapper {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;

      div {
        margin: 0 10px;
      }
    }

    .inner_wrapper {
      padding: 30px;
    }
    .artwork-t {
      position: absolute;
      left: 30px;
      top: 30px;
    }

    .artwork-b {
      position: absolute;
      bottom: 30px;
      right: 30px;
    }

    .title {
      font-size: 35px;
      line-height: 38px;
      font-weight: 500;
      color: ${colors.black};
      margin-bottom: 30px;
    }

    .subtitle {
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      color: ${colors.black};
      margin-bottom: 48px;
    }
  }
`;

const ConnectWithInvite = ({ setIsJoin }) => {
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    trackAmplitude('Connection Link', 'Open', 'WebPage');
  }, []);

  const copyToClipboard = () => {
    trackAmplitude('Connection Link', 'Link copied', 'WebPage');

    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        messageApi.open({
          type: 'success',
          content: 'Successfully copied',
        });
      })
      .catch(() => {
        messageApi.open({
          type: 'error',
          content: 'Error copying text to clipboard:',
        });
      });
  };

  return (
    <ConnectInviteWrapper>
      {contextHolder}
      <div className='inner_wrapper'>
        <img src={Logo} alt='logo' className='logo' />
        <img src={ArtworkTop} alt='artwork' className='artwork-t' />
        <img src={ArtworkBottom} alt='artwork' className='artwork-b' />
        <Paragraph fz={64} fw={900} mb={60} color={colors.main_color} lh='70px' className='title'>
          It seems that you are <br /> on a desktop
        </Paragraph>
        <Paragraph fz={21} mb={40} color='#0C615A' fw={400} lh='25px' className='subtitle'>
          Copy the link to your mobile browser <br /> to continue with connection invite
        </Paragraph>
        <div className='buttons_wrapper'>
          <div>
            <Button type='primary' radius='50px' width='180px' onClick={copyToClipboard}>
              Copy Link
            </Button>
          </div>
          <div>
            <Button
              type='outlined'
              radius='50px'
              width='180px'
              color={colors.main_color}
              border={`1px solid ${colors.main_color}`}
              onClick={() => setIsJoin(false)}
            >
              Back to sign up
            </Button>
          </div>
        </div>
      </div>
    </ConnectInviteWrapper>
  );
};

export default ConnectWithInvite;
