export const API_ROOT = process.env.REACT_APP_API_ROOT || 'https://api.joinubqt.com/';
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID || '1438042926788214';
export const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  '124211129663-kmgsspgu6sfe529hrs415479obc5n7l9.apps.googleusercontent.com';
export const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID || '77mceguwd6w33d';
export const GOOGLE_GEOCODE_API_KEY =
  process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY || 'AIzaSyAhNnpKjb5NiaxDVf5NNXKZxAOD7sKVx8M';
export const REACT_APP_WEBSITE_GOOGLE_API_KEY =
  process.env.REACT_APP_WEBSITE_GOOGLE_API_KEY || 'AIzaSyD39zxVrvEp7zQvQiXq_mUPqUV-vk1Drns';
export const IOS = process.env.REACT_APP_IOS || 'https://apps.apple.com/ca/app/ubqt/id6447530154';
export const ANDROID =
  process.env.REACT_APP_ANDROID ||
  'https://play.google.com/store/apps/details?id=com.joinubqt.ubqt&pli=1';
export const WINDOWS =
  process.env.REACT_APP_WINDOWS ||
  'https://play.google.com/store/apps/details?id=com.joinubqt.ubqt&pli=1';
export const MACOS =
  process.env.REACT_APP_MACOS || 'https://apps.apple.com/ca/app/ubqt/id6447530154';
