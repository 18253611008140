import { Progress } from 'antd';
import styled from 'styled-components';
import TripHeaderV2 from 'components/molecules/trip-header-v2';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../styles/colors';
import logo from '../../../assets/images/logo.png';
import placeholder from '../../../assets/images/placeholder_trip.png';

export const SignWrapperComp = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'isFromInvite',
})`
  display: flex;
  justify-content: center;

  .signup_wrapper {
    background-color: ${colors.white};
    border-radius: 20px;
    width: ${props => {
      return props.isFromInvite ? '100%' : '95%';
    }};
  }
  .community_title {
    font-size: 27px;
    margin: 20px 20px;
    font-weight: 500;
    line-height: 32px;
  }

  .community_subtitle {
    font-size: 16px;
    margin: 20px 20px;
    font-weight: 400;
    line-height: 22px;
  }
  .community_message {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: ${colors.black};
  }
  .message_container {
    background-color: ${colors.veryLightGrey};
    padding: 20px;
  }

  .description {
    margin-top: 40px;
    padding: 0px 20px;
    color: ${colors.black};
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
  }

  .divider {
    margin: 15px 0;

    .ant-divider-inner-text {
      color: ${colors.grey};
      font-size: 14px;
      font-weight: 400;
    }

    &:before,
    &:after {
      border-block-start-color: #e6e6e6;
    }
  }

  .social_wrapper {
    .google_icon {
      width: 28px;
      height: 28px;
      cursor: pointer;
    }

    .fb_wrapper {
      height: 28px;

      button {
        background-color: #fff;
        width: fit-content;
        height: 28px;
        padding: 0;
        border-radius: 50%;
        border: none;

        img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
  .community_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .logo {
      position: relative;
      text-align: right;
      margin-top: 30px;
      margin-left: 20px;
      width: 120px;
      height: 32px;
    }

    .community_pic {
      position: relative;
      margin-top: 30px;
      margin-right: 20px;
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }
  @media screen and (min-width: 1000px) {
    display: block;

    .community_header {
      .community_pic {
        position: absolute;
        top: 40px;
        right: 50px;
        margin-top: 0px;
        margin-right: 0px;
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }
      .logo {
        position: absolute;
        margin-top: 0px;
        top: 40px;
        left: 50px;
        width: 120px;
        height: 32px;
      }
    }
  }
  @media screen and (min-width: 500px) {
    .signup_wrapper {
      width: 500px;
    }

    .community_title {
      font-size: 60px;
      margin: 30px 0px;
      font-weight: 500;
      line-height: 64px;
    }
    .community_subtitle {
      font-size: 21px;
      margin: 30px 0px;
      font-weight: 400;
      line-height: 22px;
    }
    .community_message {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: ${colors.black};
    }
    .message_container {
      background-color: ${colors.veryLightGrey};
      padding: 20px;
    }
  }

  @media screen and (max-width: 576px) {
    .logo_wrapper {
      margin-bottom: 30px;
    }
    .title {
      font-size: 32px;
      line-height: 40px;
    }
    .subtitle {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
`;

const SignWrapper = ({
  children,
  percent = 50,
  communityInfo,
  connectionInfo,
  onClose,
  handleBack,
}) => {
  const { t } = useTranslation();
  const isFromInvite = !!communityInfo || !!connectionInfo;

  const CommunitySection = communityInfo && (
    <>
      <div className='community_header'>
        <img src={logo} alt='ubqt' className='logo' />
        <img
          src={communityInfo?.community?.image?.url || placeholder}
          alt='community'
          className='community_pic'
        />
      </div>
      <div className='community_title'>
        Welcome to <br />
        {communityInfo?.community?.name || ''}
      </div>
      <div className='community_subtitle'>
        <span style={{ color: colors.main_color }}>{communityInfo?.user?.fullName || ''}</span> has
        invited you to join{' '}
        <span style={{ color: colors.main_color }}>{communityInfo?.community?.name || ''}</span>{' '}
        community on UBQT.
      </div>
      <div className='message_container'>
        <div className='community_message'>
          {
            'Reconnect with your people, in real life. Wherever you are in the world, you’ll never miss a chance to catch up.'
          }
        </div>
      </div>
    </>
  );

  const ConnectionSection = connectionInfo && (
    <>
      <div className='community_header'>
        <img src={logo} alt='ubqt' className='logo' />
        <img
          src={connectionInfo?.user?.avatar?.url || placeholder}
          alt='connection'
          className='community_pic'
        />
      </div>
      <div className='community_title'>
        Connect with <br />
        {connectionInfo?.user?.fullName || ''}
      </div>
      <div className='community_subtitle'>
        <span style={{ color: colors.main_color }}>{connectionInfo?.user?.fullName || ''}</span> has
        invited you to connect on UBQT
      </div>
      <div className='message_container'>
        <div className='community_message'>
          {
            'Reconnect with your people, in real life. Wherever you are in the world, you’ll never miss a chance to catch up.'
          }
        </div>
      </div>
    </>
  );

  return (
    <SignWrapperComp isFromInvite={isFromInvite}>
      <div className='signup_wrapper'>
        {CommunitySection}
        {ConnectionSection}
        {isFromInvite ? (
          <div className='description'>{'Signup'}</div>
        ) : (
          <TripHeaderV2
            title={t('app.createAnAccount')}
            onClose={isFromInvite ? null : onClose}
            handleBack={handleBack}
          />
        )}
        <Progress
          percent={percent}
          showInfo={false}
          strokeColor={colors.main_color}
          strokeLinecap='square'
          className='progress'
          strokeWidth={2}
        />
        {children}
      </div>
    </SignWrapperComp>
  );
};

export default SignWrapper;
