import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../styles/colors';

const OrDividerWrapper = styled.div`
  .divider_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .or-text {
    display: flex;
    align-items: center;
    margin: 0 10px;
    color: ${colors.middleGrey};
    font-size: 13px;
    line-height: 22px;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: ${colors.dividerColor};
  }
`;

const OrDivider = () => {
  const { t } = useTranslation();
  return (
    <OrDividerWrapper>
      <div className='divider_wrapper'>
        <div className='line'></div>
        <div className='or-text'>{t('app.or')}</div>
        <div className='line'></div>
      </div>
    </OrDividerWrapper>
  );
};

export default OrDivider;
