import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles/colors';

const DateBoxWrapper = styled.div`
  display: flex;
  flex-direction: row; // Stack children vertically
  align-items: center; // Center align horizontally
  justify-content: center; // Center align vertically
  padding: 2px 8px;
  border-radius: 10px; // Rounded corners
  background-color: ${colors.veryLightGrey}; // No background

  .month-year {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: ${({ dayOnLeft }) => (dayOnLeft ? '0 0 0 4px' : '0 4px 0 0')};
  }

  // Nested styles for DayMonth
  .day {
    font-size: 28px;
    font-weight: 500;
    color: ${colors.main_color}; // Text color
  }

  .month {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #000; // Text color
  }

  .year {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    color: #999; // Lighter text color for the year
  }
`;

const DateBox = ({ date, dayOnLeft = true }) => {
  // Format the date parts
  const day = date.format('DD'); // e.g., "18"
  const month = date.format('MMM'); // e.g., "Sep"
  const year = date.format('YYYY'); // e.g., "2025"

  return (
    <DateBoxWrapper dayOnLeft={dayOnLeft}>
      {dayOnLeft && <div className='day'>{day}</div>}
      <div className='month-year'>
        <div className='month'>{month}</div>
        <div className='year'>{year}</div>
      </div>
      {!dayOnLeft && <div className='day'>{day}</div>}
    </DateBoxWrapper>
  );
};

export default DateBox;
