import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { colors } from '../../styles/colors';
import Logo from '../../assets/images/logo.png';
import ArtworkTop from '../../assets/images/artwork-thankyou-t.png';
import ArtworkBottom from '../../assets/images/artwork-thankyou-b.png';
import AppStoreImage from '../../assets/images/app-store.png';
import GooglePlayImage from '../../assets/images/google-play.png';
import UBQTqr from '../../assets/images/ubqt-qrcode.png';

const ThankYouWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  background-color: ${colors.white};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .artwork-t {
    position: absolute;
    width: 143.35px;
    height: 96px;
    top: 20px;
    left: 20px;
    z-index: 1;
  }

  .artwork-b {
    position: absolute;
    width: 133px;
    height: 133px;
    bottom: 20px;
    right: 20px;
    z-index: 1;
  }

  .close {
    position: absolute;
    right: 20px;
    top: 40px;
    font-size: 25px;
    color: ${colors.darkTextColor};
  }

  .inner_wrapper {
    padding: 0px 20px;
  }

  .title {
    font-size: 35px;
    line-height: 38px;
    font-weight: 500;
    color: ${colors.black};
    margin-bottom: 30px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: ${colors.black};
    margin-bottom: 48px;
  }

  .qrcode {
    display: none;
  }

  .logo {
    position: relative;
    width: 150px;
    height: 40.75px;
    margin-bottom: 40px;
    z-index: 2;
  }

  .market_images_wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    z-index: 2;

    img {
      height: 46px;
      width: 179px;
      margin: 10px 0px;
    }
  }

  @media screen and (min-width: 500px) {
    max-width: 100vw;
    min-height: 100vh;

    .logo {
      margin-bottom: 50px;
    }

    .close {
      right: 40px;
    }

    .market_images_wrapper {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;

      img {
        height: 46px;
        width: 179px;
        margin: 0 10px;
      }
    }

    .inner_wrapper {
      padding: 30px;
    }
    .artwork-t {
      position: absolute;
      left: 30px;
      top: 30px;
    }

    .artwork-b {
      position: absolute;
      bottom: 30px;
      right: 30px;
    }

    .title {
      font-size: 35px;
      line-height: 38px;
      font-weight: 500;
      color: ${colors.black};
      margin-bottom: 30px;
    }

    .qrcode {
      display: inline;
      width: 192px;
      height: 192px;
      margin-bottom: 48px;
    }

    .subtitle {
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      color: ${colors.black};
      margin-bottom: 48px;
    }
  }
`;

const ThankYou = ({
  title = 'Thank you for signing up',
  subtitle = 'Please download the mobile app and login',
  subtitle2 = 'the same credentials to plan catchups',
  hasImages = true,
  onClose,
}) => {
  return (
    <ThankYouWrapper>
      {onClose && (
        <div className='icon' on onClick={onClose}>
          <CloseOutlined alt='close' className={'close'} size={'50px'} />
        </div>
      )}
      <img src={ArtworkTop} alt='artwork' className='artwork-t' />
      <img src={ArtworkBottom} alt='artwork' className='artwork-b' />

      <div className='inner_wrapper'>
        <img src={Logo} alt='logo' className='logo' />
        <div className='title'>{title}</div>
        <div className='subtitle'>
          {subtitle}
          <br />
          {subtitle2}
        </div>
        <img src={UBQTqr} alt='logo' className='qrcode' />

        {hasImages && (
          <div className='market_images_wrapper'>
            <a
              href='https://apps.apple.com/ca/app/ubqt/id6447530154'
              target='_blank'
              rel='noreferrer'
            >
              <img src={AppStoreImage} alt='app store' />
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=com.joinubqt.ubqt&pli=1'
              target='_blank'
              rel='noreferrer'
            >
              <img src={GooglePlayImage} alt='google play' />
            </a>
          </div>
        )}
      </div>
    </ThankYouWrapper>
  );
};

export default ThankYou;
