/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import Header from '../components/molecules/header';
import GetAppQr from '../components/molecules/get-app-qr';
import PageLoader from '../components/molecules/page-loader';
import AvatarSection from '../components/molecules/avatar-section';
import GetTheApp from '../components/molecules/get-the-app';
import useAuth from '../utils/hooks/useAuth';
import NotFound from '../components/molecules/not-found';
import About from '../components/molecules/about';
import CatchMeCard from '../components/molecules/cards/catch-me-card';
import useCatchMe from '../utils/hooks/useCatchMe';
import { joinCatchMeApi } from '../services/catch-me';
import { extractAddressComponent } from '../utils/helpers';
import InvitedUpcoming from '../components/molecules/invited-upcoming';
import { trackAmplitude } from '../utils/amplitude';

const MainWrapper = styled.div`
  @media (min-width: 576px) {
    padding: 0px;
    margin: 0px 0px;
  }

  @media (min-width: 768px) {
    padding: 0px 50px;
  }

  .mobile_view {
    display: block;
  }

  .card_wrapper {
    padding: 20px;
    background-color: ${colors.grayVeryLight};
  }
  .desktop_view {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .mobile_view {
      display: block;
      margin: 0px -50px;
    }
  }

  @media screen and (min-width: 1024px) {
    .mobile_view {
      display: none;
    }

    .desktop_view {
      display: flex;
      justify-content: space-between;
      margin: 0px -50px;
      overflow: auto;
      background-color: ${colors.faded_grey};
    }
  }
  @media (min-width: 1024px) {
    .left-section {
      margin: 0 2%;
    }
    .right-section {
      margin-right: 10%;
      margin-bottom: 30px;
    }

    .left-section {
      flex: 0 0 60%;
      max-width: 500px;
      margin-top: 30px;
    }

    .right-section {
      flex: 0 0 20%;
      max-width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
    }
  }
`;

const CatchMePage = () => {
  const { stateLoading, catchData, user, expiryDate, token, userSlug, followed, errors } =
    useCatchMe();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { guestUser } = useAuth();
  const [isJoin, setIsJoin] = useState(false);

  const joinedObj = {
    catchId: catchData?.id,
    guestEmail: guestUser?.email,
  };

  useEffect(() => {
    setIsJoin(followed);
  }, [followed]);

  useEffect(() => {
    trackAmplitude('Catchme', 'Open', 'WebPage');
  }, []);

  const followedCatchMe = async () => {
    try {
      const res = await joinCatchMeApi(joinedObj);
      setIsJoin(true);
      trackAmplitude('Catchme', 'Followed', 'WebPage');
      notification.success({ message: res?.data?.message });
    } catch (error) {
      notification.error({ message: error.message });
    }
  };

  const country = extractAddressComponent(catchData?.place?.address_components, 'country');
  const city = extractAddressComponent(catchData?.place?.address_components, 'locality');
  const address = extractAddressComponent(
    catchData?.place?.address_components,
    'administrative_area_level_1'
  );

  if (stateLoading) {
    return <PageLoader />;
  }

  if (errors?.message || !user) {
    return <NotFound reason={errors.message} />;
  }

  return (
    <MainWrapper>
      <div className='main_wrapper'>
        <Header />
        {!errors?.message && user?.fullName && (
          <>
            <InvitedUpcoming
              description={t('app.inviteMessageCatchMe', { userName: user?.fullName })}
            />
            <AvatarSection
              avatarUrl={user?.avatar?.url}
              name={user?.fullName}
              expiryDate={expiryDate}
            />
            <div className='mobile_view'>
              <div className='card_wrapper'>
                <CatchMeCard
                  isCatchMeJoinCard={false}
                  isJoin={isJoin}
                  name={catchData?.place?.name}
                  city={city}
                  country={country}
                  address={address}
                  startDateTimeUTC={catchData?.startDateTimeUTC}
                  endDateTimeUTC={catchData?.endDateTimeUTC}
                  walk={catchData?.note}
                  onButtonClick={() => {
                    if (guestUser) {
                      followedCatchMe();
                    } else {
                      navigate(
                        `${`/catch-me-detail`}/${
                          catchData?.id
                        }?token=${token}&userSlug=${userSlug}&avatar=${user?.avatar?.url}`,
                        { state: { catchData, user } }
                      );
                    }
                  }}
                />
              </div>
              <GetTheApp name={user?.fullName} />
            </div>
            <div className='desktop_view'>
              <div className='left-section'>
                <CatchMeCard
                  isCatchMeJoinCard={false}
                  isJoin={isJoin}
                  name={catchData?.place?.name}
                  city={city}
                  country={country}
                  address={address}
                  startDateTimeUTC={catchData?.startDateTimeUTC}
                  endDateTimeUTC={catchData?.endDateTimeUTC}
                  walk={catchData?.note}
                  onButtonClick={() => {
                    if (guestUser) {
                      followedCatchMe();
                    } else {
                      navigate(
                        `${`/catch-me-detail`}/${
                          catchData?.id
                        }?token=${token}&userSlug=${userSlug}&avatar=${user?.avatar?.url}`,
                        { state: { catchData, user } }
                      );
                    }
                  }}
                />
              </div>
              <div className='right-section'>
                <GetAppQr name={user?.fullName} />
              </div>
            </div>
          </>
        )}
        <About />
      </div>
    </MainWrapper>
  );
};

export default CatchMePage;
