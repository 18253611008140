import styled from 'styled-components';
import React from 'react';
import { CloseOutlined, LeftOutlined } from '@ant-design/icons';
import { colors } from '../../styles/colors';

const TripHeaderV2Wrapper = styled.div`
  .inner_wrappers {
    display: flex;
    align-items: center; /* Vertically center items */
    justify-content: space-between; /* Space between the center section and right-aligned icon */
    padding: 16px 20px 20px 20px;
    background-color: ${colors.white};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .center_section {
    display: flex; /* To group avatar and description */
    align-items: center; /* Vertically center avatar and description */
    margin: 0 auto; /* Push the section to the center */
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 10px; /* Space between avatar and description */
  }

  .description {
    font-size: 22px;
    line-height: 22px
    font-weight: 500;
  }

  .icon {
    background-color: ${colors.white};
    cursor: pointer;
  }
`;

const TripHeaderV2 = ({ onClose, onBack, avatarUrl, title }) => {
  return (
    <TripHeaderV2Wrapper>
      <div className='inner_wrappers'>
        {onBack && (
          <div className='icon' on onClick={onBack}>
            <LeftOutlined style={{ color: colors.main_color }} />
          </div>
        )}
        <div className='center_section'>
          {avatarUrl && <img src={avatarUrl} alt='avatar' className='avatar' />}
          <div className='description'>{title}</div>
        </div>
        {onClose && (
          <div className='icon' on onClick={onClose}>
            <CloseOutlined style={{ color: colors.greyText }} />
          </div>
        )}
      </div>
    </TripHeaderV2Wrapper>
  );
};

export default TripHeaderV2;
