import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TripHeaderV2 from 'components/molecules/trip-header-v2';
import UBQTLogo from 'components/molecules/ubqt-logo';
import TripCard from 'components/molecules/trip-card';
import { colors } from '../../../styles/colors';
import { Button } from '../../atoms';
import useApiCall from '../../../utils/hooks/useApiCall';
import { fetchSingleTripApi } from '../../../services/trips';
import PageLoader from '../../molecules/page-loader';
import useAuth from '../../../utils/hooks/useAuth';
import Celebration from '../../../assets/images/celebration.png';
import Ellipse from '../../../assets/images/followed_artwork/ellipse.png';
import Rectangle from '../../../assets/images/followed_artwork/rectangle.png';

const FollowingTripWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: ${colors.light_purple};

  .following_wrapper {
    background-color: ${colors.white};
    border-radius: 20px;
    width: 95%;
  }
  .celebration {
    display: flex;
    justify-content: center;
  }
  .form_section {
    background-color: ${colors.white};
    margin-bottom: 15px;
  }
  .text {
    color: ${colors.greyText};
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    font-weight: 400;
  }
  .button_section {
    text-align: center;
    margin-top: 15px;
  }
  .button {
    border-radius: 25px;
    padding: 10px 30px 10px 30px;
  }
  .card_wrapper {
    background-color: ${colors.faded_grey};
    padding: 20px;
  }
  .button_connect {
    background-color: ${colors.white};
    border: 1px solid ${colors.main_color};
    color: ${colors.main_color};
    border-radius: 20px;
  }
  .title_section {
    font-size: 27px;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    line-height: 27px;
  }
  .email_text {
    color: ${colors.black};
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    font-weight: 400;
  }
  .text_section {
    font-size: 27px;
    text-align: center;
    line-height: 27px;
    margin-bottom: 20px;
    font-weight: 500;
    color: ${colors.black};
  }
  .plan_section {
    padding: 0px 35px 25px 35px;
  }
  .heading_section {
    padding: 20px 10px 0px 10px;
  }
  .no_thanks {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 30px;
    color: ${colors.middleGrey};
    font-size: 16px;
    line-height: 18px;
    text-decoration: underline;
    cursor: pointer;
  }
  .artwork-top {
    display: flex;
    justify-content: end;
    margin-bottom: -10px;
    margin-right: -15px;
  }
  .artwork-bottom {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: -40px;
    margin-left: -15px;
  }
  .rectangle {
    display: flex;
    justify-content: center;
  }
  .ellipse {
  }
  @media screen and (min-width: 500px) {
    .card_wrapper {
      background-color: ${colors.faded_grey};
      padding: 20px 80px;
    }
    .following_wrapper {
      margin: 25px;
      width: auto;
    }
  }
`;

const FollowedTrip = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { guestUser } = useAuth();

  const [searchParams] = useSearchParams();
  const slug = searchParams.get('userSlug');
  const avatar = searchParams.get('avatar');
  const time = searchParams.get('token');
  const { t } = useTranslation();

  const [trip, tripLoading, tripCall] = useApiCall(fetchSingleTripApi);

  useEffect(() => {
    const obj = {
      id: params.tripId,
      token: time,
      userSlug: slug,
    };
    tripCall(obj);
  }, []);

  if (tripLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <FollowingTripWrapper>
        <UBQTLogo />
        <div className='following_wrapper'>
          <TripHeaderV2
            avatarUrl={avatar}
            onClose={() => navigate(`/trips/${slug}?token=${time}&guestEmail=${guestUser?.email}`)}
            title={t('app.followTripHeader')}
          />
          <div className='celebration'>
            <img src={Celebration} alt='congratulations' />
          </div>
          <div className='title_section'>
            {t('app.following')} <br /> {trip?.trip?.userId?.fullName}'s {t('app.trip')}
          </div>
          <div className='card_wrapper'>{trip && <TripCard trip={trip?.trip} />}</div>
          <div className='form_section'>
            <div className='heading_section'>
              <div className='text'>{t('app.receiveUpdates')}</div>
              <div className='email_text'>{guestUser.email}</div>
            </div>
            <div className='plan_section'>
              <div className='artwork-top'>
                <img src={Rectangle} alt='rectangle' className='rectangle' />
              </div>
              <div className='text_section'>
                {t('app.wantToPlan')} <br />
                {t('app.catchup', { name: trip?.trip?.userId?.fullName })}
              </div>
              <div className='button_section'>
                <Button
                  className='button'
                  onClick={() => navigate(`/sign-up?token=${time}&userSlug=${slug}`)}
                >
                  {t('app.createAccount')}
                </Button>
              </div>
              <div className='artwork-bottom'>
                <img src={Ellipse} alt='ellipse' className='ellipse' />
              </div>
            </div>

            <div
              className='no_thanks'
              onClick={() =>
                navigate(`/trips/${slug}?token=${time}&guestEmail=${guestUser?.email}`)
              }
            >
              {t('app.noThanks')}
            </div>
          </div>
        </div>
      </FollowingTripWrapper>
    </>
  );
};

export default FollowedTrip;
