import styled from 'styled-components';
import React from 'react';
import logo from '../../assets/images/logo.png';

const UBQTLogoWrapper = styled.div`
  .logo {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .logo {
      display: block;
      position: absolute;
      top: 40px;
      left: 50px;
      width: 120px;
      height: 32px;
      z-index: 1;
    }
  }
`;

const UBQTLogo = ({ onClose }) => {
  return (
    <UBQTLogoWrapper>
      <img src={logo} alt='ubqt' className='logo' />
    </UBQTLogoWrapper>
  );
};

export default UBQTLogo;
