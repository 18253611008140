import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { defFormLayout } from '../../../constants/etc';
import { FormItem, Input, Form, InputPassword, Button } from '../../atoms';
import { colors } from '../../../styles/colors';
import { trackAmplitude } from '../../../utils/amplitude';

const SignupStep2Wrapper = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'isFromInvite',
})`
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    display: none;
  }
  .form_section {
    background-color: ${colors.white};
    margin: 20px 30px;
  }
  .button_section {
    text-align: center;
    margin-top: 20px;
  }
  .button {
    border-radius: 25px;
  }

  @media screen and (min-width: 500px) {
    .logo {
      display: block;
      position: absolute;
      top: 40px;
      left: 50px;
      width: 120px;
      height: 32px;
    }
    .form_section {
      margin: ${props => {
        return props.isFromInvite ? '26px 20px 44px 20px;' : '26px 100px 44px 100px;';
      }};
    }
  }
`;

const SecondStepForm = ({
  form,
  handleSecSubmit,
  email,
  settings,
  setIsJoin,
  communityInfo,
  isFromInvite,
}) => {
  const formValues = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState(false);

  useEffect(() => {
    trackAmplitude('Signup second page', 'Open', 'WebPage');
  }, []);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => {
        setSubmittable(true);
      })
      .catch(() => setSubmittable(false));
  }, [form, formValues]);

  const handleSubmit = async () => {
    const values = await form.validateFields();
    handleSecSubmit({ email: values.email, password: values.password });
  };

  return (
    <SignupStep2Wrapper isFromInvite={!!isFromInvite}>
      <div className='form_section'>
        <Form intialValues={email} form={form} {...defFormLayout} width='100%'>
          <FormItem
            name='email'
            initialValue={email || ''}
            rules={[
              { required: true, message: 'Email is required' },
              { type: 'email', message: 'Email is invalid!' },
            ]}
          >
            <Input placeholder='Email address' />
          </FormItem>
          <FormItem
            name='password'
            help='Password must contain at least 8 characters, one uppercase, one number and one special case character'
            rules={[
              { required: true, message: 'Password is required!' },
              {
                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
                message:
                  'Password must contain at least 8 characters, one uppercase, one number and one special case character',
              },
              ({ getFieldValue, setFields }) => ({
                validator(_, value) {
                  if (!getFieldValue('confirmPassword')) {
                    return Promise.resolve();
                  }
                  if (getFieldValue('confirmPassword') !== value) {
                    setFields([
                      {
                        name: 'confirmPassword',
                        value: getFieldValue('confirmPassword'),
                        errors: ['Password and Confirm password must be identical!'],
                      },
                    ]);
                  } else {
                    setFields([
                      {
                        name: 'confirmPassword',
                        value: getFieldValue('confirmPassword'),
                        errors: null,
                      },
                    ]);
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputPassword autoComplete='new-password' placeholder='Password' />
          </FormItem>
          <FormItem
            name='confirmPassword'
            rules={[
              { required: true, message: 'Confirm password is required!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Password and Confirm password must be same!'));
                },
              }),
            ]}
          >
            <InputPassword autoComplete='new-password' placeholder='Confirm password' />
          </FormItem>

          <FormItem shouldUpdate={true}>
            {() => (
              <div className='button_section'>
                <Button
                  width='220px'
                  className='button'
                  htmlType='submit'
                  onClick={handleSubmit}
                  disabled={!submittable}
                >
                  Create your account
                </Button>
              </div>
            )}
          </FormItem>
          {isFromInvite ? (
            <div style={{ color: colors.black, textAlign: 'center' }}>
              Already signed up?{' '}
              <span
                style={{ color: colors.main_color, textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => setIsJoin(true)}
              >
                {communityInfo ? 'Join the community' : 'Connect now'}
              </span>
            </div>
          ) : null}
        </Form>
      </div>
    </SignupStep2Wrapper>
  );
};

export default SecondStepForm;
