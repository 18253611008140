import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../styles/colors';

import logo from '../../assets/images/logo.png';
import GetQRArtworkImg from '../../assets/images/get-qr-artwork.png';
import UBQTQR from '../../assets/images/ubqt-qrcode.png';

const GetAppQrWrapper = styled.div`
  .get_app_wrapper {
    padding: 30px 30px 20px 30px;
    background-color: ${colors.white};
  }

  .logo {
    width: 60px;
    margin-top: 10px;
  }

  .market_images_wrapper {
    display: flex;
    align-content: center;
    justify-content: space-between;
  }

  .app_store {
    width: 90%;
  }

  .subtitle {
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    color: ${colors.white};
    margin-bottom: 15px;
  }

  .artwork {
    width: 160px;
    height: 108px;
    margin-top: 20px;
  }

  .qrcode {
    margin-right: -11px;
    width: 140px;
    height: 140px;
  }

  .qr_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: bottom;
    margin-top: 50px;
  }

  .heading_wrapper_img {
    display: flex;
    justify-content: space-between;
    align-items: top;
  }

  .plan_title {
    font-size: 35px;
    font-weight: 400;
    line-height: 36px;
    color: ${colors.black};
    margin-bottom: 15px;
    margin-right: 60px;
    width: 200px;
  }

  .get_title {
    font-size: 21px;
    font-weight: 400;
    line-height: 20px;
    color: ${colors.black};
    text-align: left;
    margin-top: 30px;
  }

  .reconnect {
    font-size: 21px;
    font-weight: 400;
    line-height: 20px;
    color: ${colors.black};
    text-align: left;
  }

  .m-b {
    margin-bottom: 20px;
  }
`;

const GetAppQr = ({ name }) => {
  const { t } = useTranslation();

  return (
    <GetAppQrWrapper>
      <div className='get_app_wrapper'>
        <div className='heading_wrapper_img'>
          <div className='plan_title'>
            {t('app.wantToPlan')}
            <br />
            {t('app.catchup', { name })}
          </div>
          <div>
            <img src={logo} alt='app store' className='logo' />
          </div>
        </div>
        <div className='m-b'>
          <div className='get_title'>{t('app.getTitle')}</div>
          <div className='reconnect'>{t('app.reconnect')}</div>
        </div>
        <div className='qr_wrapper'>
          <div>
            <img src={GetQRArtworkImg} alt='artwork' className='artwork' />
          </div>
          <div>
            <img src={UBQTQR} alt='artwork' className='qrcode' />
          </div>
        </div>
      </div>
    </GetAppQrWrapper>
  );
};

export default GetAppQr;
