import styled from 'styled-components';
import { notification, Spin } from 'antd';
import { useState } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import UBQTLogo from 'components/molecules/ubqt-logo';
import { sendConnectionInvite } from 'services/connect';
import { Form } from '../atoms';
import FirstStepForm from './sign-up/first-step-form';
import { fetchCallbackApi, updateUserApi, userRegisterApi } from '../../services/settings';
import useApiCall from '../../utils/hooks/useApiCall';
import SecondStepForm from './sign-up/second-step-form';
import SignWrapper from './sign-up/sign-wrapper';
import ThankYou from '../molecules/thank-you';
import JoinCommunity from './join-community';
import useAuth from '../../utils/hooks/useAuth';
import { splitName } from '../../utils/helpers';
import { trackAmplitude } from '../../utils/amplitude';
import { colors } from '../../styles/colors';
import ArtworkTop from '../../assets/images/get-qr-artwork.png';
import ArtworkBottom from '../../assets/images/artwork-thankyou-b.png';
import ConnectWithInvite from './connect-with-invite';

const HomeWrapper = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'isFromInvite',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => {
    return props.isFromInvite ? colors.white : colors.light_purple;
  }};
  min-height: 100vh;

  .artwork-t {
    display: none;
  }

  .artwork-b {
    display: none;
  }

  @media (min-width: 768px) {
    .artwork-b {
      display: block;
      position: absolute;
      width: 133px;
      height: 133px;
      bottom: 20px;
      right: 20px;
      z-index: 1;
    }

    .artwork-t {
      display: block;
      position: absolute;
      width: 143.35px;
      height: 96px;
      bottom: 20px;
      left: 20px;
      z-index: 1;
    }
  }
`;

const Artwork = () => {
  return (
    <>
      <img src={ArtworkTop} alt='artwork' className='artwork-t' />
      <img src={ArtworkBottom} alt='artwork' className='artwork-b' />
    </>
  );
};

const Home = ({ settings, communityInfo, connectionInfo }) => {
  const [firstFormRef] = Form.useForm();
  const navigate = useNavigate();
  const [secFormRef] = Form.useForm();
  const [socialData, socialDataLoading, socialDataCall] = useApiCall(fetchCallbackApi);
  const [, updateUserLoading, updateUserCall] = useApiCall(updateUserApi);
  const [, userRegisterLoading, userRegisterCall] = useApiCall(userRegisterApi);
  const [, sendConnectionInviteLoading, sendConnectionInviteCall] =
    useApiCall(sendConnectionInvite);
  const [firstStepData, setFirstStepData] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isJoin, setIsJoin] = useState(false);
  const { guestUser } = useAuth();
  const [firstName, lastName] = splitName(guestUser?.name);
  const [searchParams] = useSearchParams();
  const params = useParams();
  const time = searchParams.get('token');
  const slug = searchParams.get('userSlug');
  const from = searchParams.get('from') || 'trips';

  const handleSocialSign = (type, token, code, redirectUri) => {
    socialDataCall(
      {
        type,
        access_token: token,
        access_code: code,
        redirectUri,
      },
      res => {
        if (res?.user?.newUser) {
          firstFormRef.setFieldsValue(res?.user || {});
        } else {
          handleExistingSubmit(res?.user);
        }
      }
    );
  };

  const handleExistingSubmit = values => {
    const updateData = {
      community: {
        communityId: communityInfo?.community?.id,
        inviterId: communityInfo?.user?.id,
      },
      newUser: false,
      firstName: values?.firstName || firstName,
    };
    updateUserCall({ id: values?.id, values: updateData }, () => {
      trackAmplitude('Signup already', 'Completed', 'WebPage');
      sendConnectionRequest(values?.id);
      if (!connectionInfo) {
        setIsSubmitted(true);
      }
    });
  };

  const handleBack = () => {
    // console.log('onback');
  };

  const handleFirstSubmit = values => {
    if (socialData?.user?.id) {
      const updateData = {
        community: {
          communityId: communityInfo?.community?.id,
          inviterId: communityInfo?.user?.id,
        },
        newUser: false,
        countryCode: `+${values?.phoneData?.dialCode || ''}`,
        countryIsoCode: values?.phoneData?.countryCode.toUpperCase(),
        firstName: values?.firstName || firstName,
        lastName: values?.lastName || lastName,
        baseLocation: values?.locationValue?.label,
        currentCity: values?.locationValue?.label,
        currentCountry: values?.locationValue?.country,
        phone: values?.phoneData?.phone.replace(values?.phoneData?.dialCode, ''),
      };
      updateUserCall({ id: socialData?.user?.id, values: updateData }, () => {
        trackAmplitude('Signup social', 'Completed', 'WebPage');
        sendConnectionRequest(socialData?.user?.id);
        if (!connectionInfo) {
          setIsSubmitted(true);
        }
      });
    } else {
      setFirstStepData(values);
    }
  };

  const sendConnectionRequest = inviterId => {
    if (connectionInfo?.user?.id) {
      sendConnectionInviteCall(
        {
          inviterId,
          token: time,
          userSlug: params?.name,
        },
        () => {
          trackAmplitude('Connection Link', 'Request Sent', 'WebPage');
          setIsSubmitted(true);
        },
        error => {
          notification.error({ message: error.message });
        }
      );
    }
  };

  const handleSecSubmit = values => {
    userRegisterCall(
      {
        ...values,
        community: {
          communityId: communityInfo?.community?.id,
          inviterId: communityInfo?.user?.id,
        },
        newUser: false,
        countryCode: `+${firstStepData?.phoneData?.dialCode || ''}`,
        countryIsoCode: firstStepData?.phoneData?.countryCode.toUpperCase(),
        firstName: firstStepData?.firstName || firstName,
        lastName: firstStepData?.lastName || lastName,
        baseLocation: firstStepData?.locationValue?.label,
        currentCity: firstStepData?.locationValue?.label,
        currentCountry: firstStepData?.locationValue?.country,
        phone: firstStepData?.phoneData?.phone.replace(firstStepData?.phoneData?.dialCode, ''),
      },
      registerResponse => {
        trackAmplitude('Signup', 'Completed', 'WebPage');
        sendConnectionRequest(registerResponse?.user?.id);
        if (!connectionInfo) {
          setIsSubmitted(true);
        }
      },
      error => {
        notification.error({ message: error.message });
      }
    );
  };
  const isCommunity = !!communityInfo?.community?.id;
  const isFromInvite = !!communityInfo?.community?.id || !!connectionInfo?.user?.id;

  const getSubtitle = () => {
    if (isFromInvite) {
      return isCommunity
        ? 'with the same credentials to access the community'
        : 'with the same credentials to connect with the person';
    }
    return 'with the same credentials to plan catchups';
  };

  let content = (
    <SignWrapper
      percent={socialData ? 100 : 50}
      communityInfo={communityInfo}
      connectionInfo={connectionInfo}
      onClose={() => navigate(`/${from}/${slug}?token=${time}`)}
    >
      <FirstStepForm
        firstName={firstName}
        lastName={lastName}
        form={firstFormRef}
        socialData={socialData}
        settings={settings}
        handleSocialSign={handleSocialSign}
        handleFirstSubmit={handleFirstSubmit}
        isFromInvite={isFromInvite}
        communityInfo={communityInfo}
        setIsJoin={setIsJoin}
      />
    </SignWrapper>
  );

  if (firstStepData) {
    content = (
      <SignWrapper
        percent={100}
        communityInfo={communityInfo}
        connectionInfo={connectionInfo}
        onClose={() => navigate(`/${from}/${slug}?token=${time}`)}
        handleBack={handleBack}
      >
        <SecondStepForm
          form={secFormRef}
          email={guestUser?.email}
          handleSecSubmit={handleSecSubmit}
          settings={settings}
          communityInfo={communityInfo}
          isFromInvite={isFromInvite}
          setIsJoin={setIsJoin}
        />
      </SignWrapper>
    );
  }

  if (isSubmitted) {
    const subtitle2 = getSubtitle();
    return (
      <ThankYou
        subtitle2={subtitle2}
        onClose={isFromInvite ? null : () => navigate(`/${from}/${slug}?token=${time}`)}
      />
    );
  }

  if (isJoin) {
    if (isCommunity) {
      return <JoinCommunity setIsJoin={setIsJoin} />;
    }

    return <ConnectWithInvite setIsJoin={setIsJoin} />;
  }

  return (
    <Spin
      spinning={
        socialDataLoading || updateUserLoading || userRegisterLoading || sendConnectionInviteLoading
      }
    >
      <HomeWrapper isFromInvite={isFromInvite}>
        {!isFromInvite && <UBQTLogo />}
        {content}
        {isFromInvite && <Artwork />}
      </HomeWrapper>
    </Spin>
  );
};

export default Home;
