import styled from 'styled-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import Button from 'components/atoms/Button/index';
import { colors } from '../../../styles/colors';
import placeholder from '../../../assets/images/placeholder_trip.png';
import DateBox from '../datebox';

const CatchUpCardWrapper = styled.div`
  background-color: ${colors.faded_grey};
  .inner_wrapper_res {
    padding: 10px 20px;
  }
  .inside_wrapper_res {
    background-color: ${colors.white};
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 15px;
  }
  .uper_wrapper {
    display: flex;
    align-items: center;
    background-color: ${colors.veryLightGrey};
    margin-top: 12px;
    border-radius: 5px;
    overflow: clip;
  }
  .lower_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .date_wrapper {
    display: flex;
    align-items: center;
  }
  .divider {
    width: 50px;
    margin-left: -4px;
    margin-right: -4px;
    position: relative; /* Required for z-index to work */
    z-index: 1;
  }
  .trips_title {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    padding: 0px 12px;
    color: ${colors.black};
  }
  .time {
    display: flex;
    align-items: center;
    background-color: ${colors.veryLightGrey};
    padding: 12px 10px;
    margin-left: 12px;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }
  .photo {
    width: 50px;
  }
  .button_section {
    height: 28px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
  .button_section_disabled {
    height: 28px;
    background-color: ${colors.light_grey};
    color: ${colors.white};
  }
`;

const CatchUpCard = ({
  meeting,
  name,
  formattedAddress,
  imageUrl,
  startDateTimeUTC,
  isJoin,
  onButtonClick,
  isButton = true,
}) => {
  const { t } = useTranslation();

  return (
    <CatchUpCardWrapper>
      <div className='inside_wrapper_res'>
        <div className='lower_wrapper'>
          <div className='date_wrapper'>
            <DateBox date={dayjs(startDateTimeUTC).local()} dayOnLeft={true} />
            <div className='time'>{dayjs(startDateTimeUTC).local().format('HH:mm')}</div>
          </div>
          {isButton && (
            <>
              {isButton && isJoin ? (
                <Button disabled className='button_section_disabled'>
                  {t('app.joined')}
                </Button>
              ) : (
                <Button className='button_section' onClick={onButtonClick}>
                  {t('app.join')}
                </Button>
              )}
            </>
          )}
        </div>
        <div className='uper_wrapper'>
          <img src={imageUrl || placeholder} alt='place_photo' className='photo' />
          <div className='trips_title'>
            {name},&nbsp;{formattedAddress}
          </div>
        </div>
      </div>
    </CatchUpCardWrapper>
  );
};

export default CatchUpCard;
