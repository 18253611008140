const { REACT_APP_WEBSITE_GOOGLE_API_KEY } = require('configs/env-vars');

const getGooglePlaceImageUrl = (photoReference, maxwidth = 100) => {
  const key = REACT_APP_WEBSITE_GOOGLE_API_KEY;
  if (!photoReference || !key) return null;
  return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=${maxwidth}&photoreference=${photoReference}&key=${key}`;
};

module.exports = {
  getGooglePlaceImageUrl,
};
