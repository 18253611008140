import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';
import { defFormLayout } from '../../../constants/etc';
import {
  Button,
  Checkbox,
  Form,
  FormItem,
  Input,
  Paragraph,
  PlaceAutocompleteInput,
  Row,
} from '../../atoms';
import GoogleSign from '../google-sign';
import LinkedinSign from '../linkedin-sign';
import FacebookSign from '../facebook-sign';
import { colors } from '../../../styles/colors';
import { trackAmplitude } from '../../../utils/amplitude';
import OrDivider from '../../molecules/or-divider';

const SignupStep1Wrapper = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'isFromInvite',
})`
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    display: none;
  }

  .form_section {
    background-color: ${colors.white};
    margin: 20px 30px;
  }
  .text {
    color: ${colors.black};
    margin-bottom: 30px;
    max-width: 400px;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    line-height: 22px;
  }

  .terms_text {
    color: ${colors.middleGrey};
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    line-height: 16px;
  }

  .social_wrapper {
    margin-bottom: 30px;
  }

  .button_section {
    text-align: center;
  }
  .button {
    border-radius: 25px;
  }
  .checkbox_text {
    color: ${colors.greyText};
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
  }

  @media screen and (min-width: 500px) {
    .logo {
      display: block;
      position: absolute;
      top: 40px;
      left: 50px;
      width: 120px;
      height: 32px;
    }
    .form_section {
      margin: ${props => {
        return props.isFromInvite ? '26px 20px 44px 20px;' : '26px 100px 44px 100px;';
      }};
    }
  }
`;

const FirstStepForm = ({
  form,
  socialData,
  settings,
  handleSocialSign,
  handleFirstSubmit,
  firstName,
  lastName,
  setIsJoin,
  isFromInvite,
  communityInfo,
}) => {
  const formValues = Form.useWatch([], form);
  const [locationValue, setLocationValue] = useState('');
  const [phoneData, setPhoneData] = useState(null);
  const [submittable, setSubmittable] = useState(false);

  useEffect(() => {
    trackAmplitude('Signup first page', 'Open', 'WebPage');
  }, []);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => {
        if (socialData) {
          setSubmittable(
            !!(socialData?.user?.id && locationValue && form.getFieldValue('isAdult'))
          );
        } else {
          setSubmittable(!!(locationValue && form.getFieldValue('isAdult')));
        }
      })
      .catch(() => setSubmittable(false));
  }, [form, formValues, locationValue]);

  const handleSubmit = async () => {
    const values = await form.validateFields();
    if (!locationValue) {
      form.setFields([{ name: 'base', errors: ['Location is required!'] }]);
    }
    handleFirstSubmit({ ...values, phoneData, locationValue });
  };

  return (
    <SignupStep1Wrapper isFromInvite={!!isFromInvite}>
      <div className='form_section'>
        <div className='text'>{'Sign up with your social media account'}</div>
        <Form intialValues={(firstName, lastName)} form={form} {...defFormLayout} width='100%'>
          {!socialData && (
            <>
              <Row gutter={20} align='middle' justify='center' className='social_wrapper'>
                <LinkedinSign handleSocialSign={handleSocialSign} />
                <GoogleSign handleSocialSign={handleSocialSign} />
                <FacebookSign handleSocialSign={handleSocialSign} />
              </Row>
              <OrDivider />
            </>
          )}
          <FormItem
            name='firstName'
            initialValue={firstName || ''}
            rules={[{ required: true, message: 'First Name is required' }]}
          >
            <Input placeholder='First Name' />
          </FormItem>
          <FormItem
            name='lastName'
            initialValue={lastName || ''}
            rules={[{ required: true, message: 'Last Name is required' }]}
          >
            <Input placeholder='Last Name' />
          </FormItem>
          <FormItem
            name='phone'
            rules={[
              {
                required: true,
                message: 'Mobile number is required!',
              },
            ]}
          >
            <PhoneInput
              country='ae'
              placeholder='Mobile Number'
              onChange={(phone, { dialCode, countryCode }) => {
                setPhoneData({ phone, countryCode, dialCode });
              }}
              countryCodeEditable={false}
              enableSearch={true}
              disableSearchIcon={true}
              searchPlaceholder='Search...'
              searchClass='phone_search'
            />
          </FormItem>
          <FormItem name='base'>
            <PlaceAutocompleteInput
              clientId={settings?.googleAPIKey}
              locationValue={locationValue}
              setLocationValue={setLocationValue}
              placeholder='Base location (city)'
              form={form}
              name='base'
            />
          </FormItem>
          {!socialData && (
            <FormItem name='isAdult' valuePropName='checked'>
              <Checkbox>
                {/* <Paragraph className='terms_text'>
                  I confirm that I’m over 18 to continue I agree to{' '}
                </Paragraph> */}
                <Paragraph className='terms_text'>
                  I confirm that I’m over 18 and I agree to{' '}
                  <a
                    href={settings?.policyLink ?? '#'}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: colors.main_color,
                    }}
                  >
                    Privacy Policy
                  </a>
                  {' and '}
                  <a
                    href={settings?.termsLink ?? '#'}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: colors.main_color,
                    }}
                  >
                    Terms & Conditions
                  </a>
                </Paragraph>
              </Checkbox>
            </FormItem>
          )}
          <FormItem shouldUpdate={true}>
            {() => (
              <div className='button_section'>
                <Button
                  className='button'
                  htmlType='submit'
                  width='150px'
                  onClick={handleSubmit}
                  disabled={!submittable}
                >
                  {socialData?.user?.id ? 'Create your account' : 'Next'}
                </Button>
              </div>
            )}
          </FormItem>
          <FormItem>
            {isFromInvite ? (
              <div style={{ color: colors.black, textAlign: 'center' }}>
                Already signed up?{' '}
                <span
                  style={{
                    color: colors.main_color,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => setIsJoin(true)}
                >
                  {communityInfo ? 'Join the community' : 'Connect now'}
                </span>
              </div>
            ) : null}
          </FormItem>
        </Form>
      </div>
    </SignupStep1Wrapper>
  );
};

export default FirstStepForm;
