/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import Button from 'components/atoms/Button/index';
import DateBox from 'components/molecules/datebox';
import { getGooglePlaceImageUrl } from 'utils/placePhoto';
import { colors } from '../../../styles/colors';
import Divider from '../../../assets/images/div-new.png';
import placeholder from '../../../assets/images/placeholder_trip.png';

const EventsCardWrapper = styled.div`
  background-color: ${colors.faded_grey};
  .inner_wrapper_res {
    padding: 10px 20px;
  }
  .inside_wrapper_res {
    background-color: ${colors.white};
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 15px;
  }
  .uper_wrapper {
    display: flex;
    align-items: center;
    background-color: ${colors.veryLightGrey};
    margin-bottom: 12px;
    border-radius: 5px;
    overflow: clip;
  }
  .date_wrapper {
    display: flex;
    align-items: center;
  }
  .lower_wrapper {
    display: flex;
    // align-items: center;
    justify-content: space-between;
  }
  .divider {
    width: 50px;
    margin-left: -4px;
    margin-right: -4px;
    position: relative; /* Required for z-index to work */
    z-index: 1;
  }
  .event_image {
    width: 50px;
    height: 35px;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .events_title {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    padding: 0px 12px;
    color: ${colors.black};
  }
  .button_section {
    height: 28px;
  }
  @media screen and (min-width: 768px) {
    .inner_wrapper_res {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .inside_wrapper_res {
      width: 49%;
    }
  }
  .button_section_disabled {
    height: 28px;
    background-color: ${colors.light_grey};
    color: ${colors.white};
  }
`;

const EventsCard = ({ data, onButtonClick, isFollowedView = false }) => {
  const { t } = useTranslation();

  return (
    <EventsCardWrapper>
      <div className='inner_wrapper_res'>
        {data?.map(event => {
          return (
            <div key={event.id} className='inside_wrapper_res'>
              <div className='uper_wrapper'>
                <img
                  src={getGooglePlaceImageUrl(event.photoReference?.photo_reference) || placeholder}
                  alt='event'
                  className='event_image'
                />
                {event.name && (
                  <div className='events_title'>
                    {event.name}, {event.city}
                  </div>
                )}
                {/* <div className='events_city'>
                    {event.city && event.country
                      ? `${event.city}, ${event.country}`
                      : event.city || event.country || ''}
                  </div> */}
              </div>
              <div className='lower_wrapper'>
                <div className='date_wrapper'>
                  <DateBox date={dayjs(event?.startDateTimeUTC).local()} dayOnLeft={true} />
                  <div>
                    <img src={Divider} alt='divider' className='divider' />
                  </div>
                  <DateBox date={dayjs(event?.endDateTimeUTC).local()} dayOnLeft={false} />
                </div>
                {isFollowedView ? (
                  <div>
                    <Button className='button_section_disabled' disabled>
                      {t('app.attending')}
                    </Button>
                  </div>
                ) : event.isFollowed ? (
                  <div>
                    <Button className='button_section_disabled' disabled>
                      {t('app.attending')}
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button className='button_section' onClick={() => onButtonClick(event.id)}>
                      {t('app.attend')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </EventsCardWrapper>
  );
};

export default EventsCard;
