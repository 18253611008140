import { useEffect, useState } from 'react';
import { defFormLayout } from 'constants/etc';
import styled from 'styled-components';
import { notification, Spin } from 'antd';
import { FormItem, Button, Col, Form, Paragraph, InputPassword, Row } from 'components/atoms';
import useApiCall from 'utils/hooks/useApiCall';
import { userResetPasswordApi } from 'services/settings';
import ThankYou from 'components/molecules/thank-you';
import { colors } from '../../../styles/colors';
import Logo from '../../../assets/images/logo.png';
import { SignWrapperComp } from '../sign-up/sign-wrapper';

export const RowWrapper = styled(Row)`
  margin: 50px 0 !important;
`;

const ResetPassword = ({ resetCode }) => {
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [, forgetPasswordLoading, forgetPasswordCall] = useApiCall(userResetPasswordApi);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => {
        setSubmittable(!!(form.getFieldValue('password') && form.getFieldValue('confirmPassword')));
      })
      .catch(() => setSubmittable(false));
  }, [form, formValues]);

  const handleSubmit = values => {
    forgetPasswordCall(
      {
        code: resetCode,
        password: values.password,
        passwordConfirmation: values.confirmPassword,
      },
      () => {
        setIsSubmitted(true);
      },
      error => {
        notification.error({ message: error.message });
      }
    );
  };

  if (isSubmitted) {
    return (
      <ThankYou
        title='Thank you'
        subtitle2='Your password is successfully updated!'
        subtitle='You can log in with your new password and access UBQT'
        hasImages={false}
      />
    );
  }

  return (
    <Spin spinning={forgetPasswordLoading}>
      <SignWrapperComp style={{ marginTop: '8%' }}>
        <div className='logo_wrapper'>
          <img src={Logo} alt='logo' style={{ width: '200px' }} />
        </div>
        <Form form={form} {...defFormLayout} width='100%' onFinish={handleSubmit}>
          <Paragraph fz={64} fw={900} mb={30} color={colors.main_color} className='title' lh='62px'>
            New Password
          </Paragraph>
          <Paragraph fz={21} mb={50} color={colors.grey} fw={400} className='subtitle' lh='25px'>
            Please enter your new password below.
          </Paragraph>
          <Row gutter={[20, 50]} margin='0' justify='center'>
            <Col lg={9} md={16} span={20}>
              <FormItem
                name='password'
                help='Password must contain at least 8 characters, one uppercase, one number and one special case character'
                rules={[
                  { required: true, message: 'Password is required!' },
                  {
                    pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
                    message:
                      'Password must contain at least 8 characters, one uppercase, one number and one special case character',
                  },
                  ({ getFieldValue, setFields }) => ({
                    validator(_, value) {
                      if (!getFieldValue('confirmPassword')) {
                        return Promise.resolve();
                      }
                      if (getFieldValue('confirmPassword') !== value) {
                        setFields([
                          {
                            name: 'confirmPassword',
                            value: getFieldValue('confirmPassword'),
                            errors: ['Password and Confirm password must be identical!'],
                          },
                        ]);
                      } else {
                        setFields([
                          {
                            name: 'confirmPassword',
                            value: getFieldValue('confirmPassword'),
                            errors: null,
                          },
                        ]);
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputPassword
                  autoComplete='new-password'
                  placeholder='New password'
                  padding='4px 0px'
                />
              </FormItem>
            </Col>
          </Row>
          <RowWrapper gutter={[20, 50]} margin='0' justify='center'>
            <Col lg={9} md={16} span={20}>
              <FormItem
                name='confirmPassword'
                rules={[
                  { required: true, message: 'Confirm password is required!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('Password and Confirm password must be same!')
                      );
                    },
                  }),
                ]}
              >
                <InputPassword
                  autoComplete='new-password'
                  placeholder='Confirm password'
                  padding='4px 0px'
                />
              </FormItem>
            </Col>
          </RowWrapper>
          <Row gutter={[20, 50]} margin='0' justify='center'>
            <Col span={24} padding='30px 0 0'>
              <FormItem shouldUpdate={true}>
                {() => (
                  <Button
                    width='220px'
                    type='outlined'
                    radius='50px'
                    onClick={() => {
                      form.submit();
                    }}
                    disabled={!submittable}
                  >
                    Reset
                  </Button>
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </SignWrapperComp>
    </Spin>
  );
};

export default ResetPassword;
