import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';

const AutoCompleteWrapper = styled.div`
  .input_parent > div:not(.css-26l3qy-menu) {
    border-radius: 0;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid ${colors.light_grey};
    height: 40px;
    box-shadow: none;
    text-align: start;

    &:hover,
    &:focus,
    &:focus-within {
      border-color: ${colors.main_color};
    }
  }

  .css-1nmdiq5-menu {
    border: none;
    background-color: #fff;

    & > div {
      box-shadow: 0 0 3px #bdbdbd;
      background-color: #fff;
      display: block;
      max-height: 150px;
    }
  }

  .css-1jqq78o-placeholder {
    color: #bababa;
  }
  .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }
  .input_parent > div {
    padding: 5px 0px;
    font-weight: 400;
    font-size: 16px;
    color: black;
  }
  .css-tlfecz-indicatorContainer {
    position: relative;
    top: 3px;
    left: 5px;
    svg path {
      d: path(
        'M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'
      ) !important;
    }
  }
`;

const PlaceAutocompleteInput = ({
  locationValue,
  setLocationValue,
  disabled,
  selectedCountry,
  clientId,
  placeholder = '',
  form,
  name,
}) => {
  const handleLocationChange = async data => {
    const geo = await geocodeByPlaceId(data?.value?.place_id);
    const info = {
      country: geo?.[0]?.address_components?.find(el => el?.types?.[0] === 'country')?.long_name,
      city: geo?.[0]?.address_components?.find(el => el?.types?.[0] === 'locality')?.long_name,
    };
    setLocationValue({ ...data, label: info?.city, country: info.country });
    if (form) {
      form.setFields([{ name, errors: data?.value ? [] : ['Location is required!'] }]);
    }
  };

  return (
    <AutoCompleteWrapper>
      <GooglePlacesAutocomplete
        apiKey={clientId}
        apiOptions={{ libraries: ['places'] }}
        selectProps={{
          className: 'input_parent',
          value: locationValue,
          onChange: handleLocationChange,
          isDisabled: disabled,
          placeholder,
          // menuIsOpen: true,
        }}
        onLoadFailed={error => {
          // eslint-disable-next-line no-console
          console.error('Could not inject Google places script', error);
        }}
      />
    </AutoCompleteWrapper>
  );
};

export default PlaceAutocompleteInput;
