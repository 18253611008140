import { useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useParams, useSearchParams } from 'react-router-dom';
import Home from '../components/organisms/home';
import useApiCall from '../utils/hooks/useApiCall';
import { fetchAllSettingsApi } from '../services/settings';
import { fetchConnectionInfoApi } from '../services/connect';
import { GOOGLE_CLIENT_ID } from '../configs/env-vars';
import NotFound from '../components/molecules/not-found';
import PageLoader from '../components/molecules/page-loader';

const ConnectPage = () => {
  const [settings, settingsLoading, settingsCall] = useApiCall(fetchAllSettingsApi);
  const [connectionInfo, connectionInfoLoading, connectionInfoCall] =
    useApiCall(fetchConnectionInfoApi);

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const routeParams = useParams();

  useEffect(() => {
    settingsCall();
    connectionInfoCall({
      token,
      userSlug: routeParams?.name,
    });
  }, []);

  if (settingsLoading || !settings || connectionInfoLoading) {
    return <PageLoader />;
  }

  if (!connectionInfo || !connectionInfo?.user) {
    return <NotFound />;
  }

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Home settings={settings} connectionInfo={connectionInfo} />
    </GoogleOAuthProvider>
  );
};

export default ConnectPage;
