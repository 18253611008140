import { useGoogleLogin } from '@react-oauth/google';
import GoogleLogo from '../../assets/images/google-logo.png';

const GoogleSign = ({ handleSocialSign }) => {
  const login = useGoogleLogin({
    onSuccess: response => {
      if (response?.access_token) {
        handleSocialSign('google', null, response?.access_token);
      }
    },
    onError: () => {},
  });

  return (
    <img
      src={GoogleLogo}
      alt='google'
      style={{ margin: '0 30px', width: 30, height: 30 }}
      className='google_icon'
      onClick={() => login()}
    />
  );
};

export default GoogleSign;
