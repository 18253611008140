import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles/colors';
import dayjs from 'dayjs';
import tripImage from '../../../assets/images/placeholder_trip.png';
import DateBox from '../datebox';
import DivLong from '../../../assets/images/div-long.png';

const CardWrapper = styled.div`
  .inside_wrapper_res {
    background-color: ${colors.white};
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  }
  .uper_wrapper {
    display: flex;
    align-items: center;
    background-color: ${colors.veryLightGrey};
    margin-bottom: 12px;
    border-radius: 5px;
    overflow: clip;
  }
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    padding: 0px 12px;
    color: ${colors.black};
  }
  .photo {
    width: 50px;
  }
  .date_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .divider {
    width: 70px;
    margin-left: -4px;
    margin-right: -4px;
    position: relative; /* Required for z-index to work */
    z-index: 1;
  }
  @media (min-width: 380px) {
    .divider {
      width: auto;
    }
  }
`;

const Card = ({ imageUrl, name, city, country, startTimeUTC, endTimeUTC }) => {
  return (
    <CardWrapper>
      <div className='inside_wrapper_res'>
        <div className='uper_wrapper'>
          <img src={imageUrl || tripImage} alt='place_photo' className='photo' />
          <div className='title'>{name && city ? `${name}, ${city}` : name || country || ''}</div>
        </div>
        <div className='date_wrapper'>
          <DateBox date={dayjs(startTimeUTC).local()} dayOnLeft={true} />
          <div>
            <img src={DivLong} alt='divider' className='divider' />
          </div>
          <DateBox date={dayjs(endTimeUTC).local()} dayOnLeft={false} />
        </div>
      </div>
    </CardWrapper>
  );
};

export default Card;
