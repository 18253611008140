import ThankYou from './thank-you';

const NotFound = ({ reason }) => {
  const title = reason || 'Oops! It seems you’re using a browser.';
  const subtitle = reason ? '' : 'Please open this link on your mobile to access the app.';

  return (
    <ThankYou
      title={title}
      subtitle={subtitle}
      subtitle2="Haven't downloaded UBQT yet? Scan the QR code to get the app!"
    />
  );
};

export default NotFound;
