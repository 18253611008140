import LinkedInIcon from 'assets/images/linkedin-logo.png';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { LINKEDIN_CLIENT_ID } from '../../configs/env-vars';

const LinkedinSign = ({ handleSocialSign }) => {
  // `${API_ROOT}auth/linkedin/callback`,
  const redirectUri = `${window.location.origin}/linkedin`;
  const { linkedInLogin } = useLinkedIn({
    clientId: LINKEDIN_CLIENT_ID,
    redirectUri,
    onSuccess: code => {
      handleSocialSign('linkedin', null, code, redirectUri);
    },

    scope: ['openid', 'profile', 'email'],
  });

  return (
    <img
      src={LinkedInIcon}
      onClick={linkedInLogin}
      alt='linkedin'
      style={{ color: '#0077b5', fontSize: 28, width: 30, height: 30 }}
    />
  );
};

export default LinkedinSign;
