import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styled from 'styled-components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { notification } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TripHeaderV2 from 'components/molecules/trip-header-v2';
import UBQTLogo from 'components/molecules/ubqt-logo';
import { colors } from '../../../styles/colors';
import { Button } from '../../atoms';
import OtpInputComponent from '../../molecules/otp-input-component';
import PageLoader from '../../molecules/page-loader';
import useAuth from '../../../utils/hooks/useAuth';
import useApiCall from '../../../utils/hooks/useApiCall';
import { resendOtp, verifyOtp } from '../../../services/settings';
import { setAmplitudeUserInfo } from '../../../utils/amplitude';
import { followEventApi } from '../../../services/events';

const VerificationEventWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${colors.light_purple};

  .verify_wrapper {
    background-color: ${colors.white};
    border-radius: 20px;
    width: 95%;
  }
  .form_section {
    background-color: ${colors.white};
    padding: 40px;
    margin-bottom: 15px;
  }
  .text {
    color: #939393;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    line-height: 18px;
  }
  .button_section {
    text-align: center;
    margin-top: 30px;
  }
  .button {
    border-radius: 25px;
    padding: 10px 30px 10px 30px;
  }
  .card_wrapper {
    background-color: ${colors.faded_grey};
    padding: 20px;
  }

  .verification {
    font-size: 27px;
    text-align: center;
    margin-bottom: 24px;
    font-weight: 400;
    line-height: 18px;
  }
  .email_text {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: ${colors.black};
  }
  .link_section {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-top: 30px;
    color: ${colors.main_color};
  }
  .code_text {
    margin-right: 5px;
    font-size: 16px;
    color: ${colors.greyText};
    font-weight: 400;
    line-height: 18px;
  }
  .link_text {
    color: ${colors.purple_link};
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 400;
    line-height: 18px;
  }

  @media screen and (min-width: 768px) {
    .verify_wrapper {
      margin: 25px;
      width: auto;
    }
    .card_wrapper {
      padding: 20px 50px;
    }
    .form_section {
      padding: 20px 80px;
    }
  }
`;

const VerificationEvent = () => {
  const params = useParams();
  const { guestUser } = useAuth();
  const navigation = useNavigate();
  const [otp, setOtp] = useState(null);
  const [searchParams] = useSearchParams();
  const avatar = searchParams.get('avatar');
  const slug = searchParams.get('userSlug');
  const time = searchParams.get('token');
  const { t } = useTranslation();

  const handleOtpChange = value => {
    if (value.length <= 4) {
      setOtp(value);
    }
  };

  useEffect(() => {
    if (otp?.length === 4) {
      const timer = setTimeout(() => saveVerifyOtp(), 1000);
      return () => clearTimeout(timer);
    }
  }, [otp]);

  const [, verifyOtpLoading, verifyOtpCall] = useApiCall(verifyOtp);
  const [, followEventLoading, followEventCall] = useApiCall(followEventApi);
  const [, resendOtpLoading, resendOtpCall] = useApiCall(resendOtp);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const verifyOtpCallWrapper = (obj, callback, errorCallback) => {
    verifyOtpCall(
      obj,
      () => callback(),
      error => errorCallback(error)
    );
  };

  const followEventCallWrapper = (followEventObj, callback, errorCallback) => {
    followEventCall(
      followEventObj,
      () => callback(),
      error => errorCallback(error)
    );
  };

  const saveVerifyOtp = () => {
    const followEventObj = {
      guestEmail: guestUser?.email,
      eventId: params.eventId,
    };
    const obj = {
      email: guestUser?.email,
      otp,
    };

    verifyOtpCallWrapper(
      obj,
      () => {
        notification.success({ message: 'OTP verified' });
        setAmplitudeUserInfo(guestUser?.email);

        followEventCallWrapper(
          followEventObj,
          () => {
            notification.success({ message: 'Followed Event successfully' });
            navigation(
              `${`/following-events`}/${
                params.eventId
              }?token=${time}&userSlug=${slug}&avatar=${avatar}`
            );
          },
          error => {
            notification.error({ message: error.message });
          }
        );
      },
      error => {
        notification.error({ message: error.message });
      }
    );
  };

  const resendOtpHandler = async () => {
    if (!executeRecaptcha) {
      notification.error({ message: 'Verification service unavailable. Please try again later.' });
      return;
    }

    const token = await executeRecaptcha('verification_event');

    resendOtpCall(
      {
        email: guestUser?.email,
        recaptchaToken: token,
      },
      () => {
        notification.success({ message: 'OTP sent successfully' });
      },
      error => {
        notification.error({ message: error.message });
      }
    );
  };

  const onBack = () => {
    navigation(`/event-detail/${params.eventId}?token=${time}&userSlug=${slug}`);
  };

  const onClose = () => {
    navigation(`/events/${slug}?token=${time}`);
  };

  if (verifyOtpLoading || followEventLoading || resendOtpLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <VerificationEventWrapper>
        <UBQTLogo />
        <div className='verify_wrapper'>
          <TripHeaderV2
            onClose={onClose}
            onBack={onBack}
            avatarUrl={avatar}
            title={'Follow Event'}
          />

          <div className='form_section'>
            <div className='verification'>{t('app.codeSent')}</div>
            <div className='text'>{t('app.codeSentMessage')}</div>
            <div className='email_text'>{guestUser?.email}</div>
            <OtpInputComponent length={4} onChange={handleOtpChange} />

            <div className='button_section'>
              <Button className='button' onClick={() => saveVerifyOtp()}>
                {t('app.continue')}
              </Button>
            </div>
            <div className='link_section'>
              <div className='code_text'>{t('app.noCode')}</div>
              <div className='link_text' onClick={resendOtpHandler}>
                {t('app.resendCode')}
              </div>
            </div>
          </div>
        </div>
      </VerificationEventWrapper>
    </>
  );
};

export default VerificationEvent;
