import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../styles/colors';

import AppStoreImage from '../../assets/images/app-store.png';
import GooglePlayImage from '../../assets/images/google-play.png';
import Artwork from '../../assets/images/artwork-mobile.png';

const GetAppWrapper = styled.div`
  .get_app_wrapper {
    padding: 20px 20px;
    background-color: ${colors.white};
  }
  .logo {
    width: 80px;
  }
  .artwork {
    width: 126px;
    object-fit: contain;
    object-position: top;
  }
  .market_images_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title_wrapper {
    display: flex;
    justify-content: space-between;
  }

  .app_store {
    width: 100%;
    padding: 0px 6px;
  }
  .title_plan {
    font-size: 21px;
    font-weight: 500;
    line-height: 21px;
    color: ${colors.black};
    margin-bottom: 20px;
  }
  .title_subtitle {
    display: flex-row;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .subtitle {
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    color: ${colors.black};
  }
`;

const GetTheApp = ({ name }) => {
  const { t } = useTranslation();

  return (
    <GetAppWrapper>
      <div className='get_app_wrapper'>
        <div className='title_wrapper'>
          <div className='title_subtitle'>
            <div className='title_plan'>
              {t('app.wantToPlan')}
              <br />
              {t('app.catchup', { name })}
            </div>
            <div className='subtitle'>{t('app.getApp')}</div>
          </div>
          <img src={Artwork} alt='artwork' className='artwork' />
        </div>
        <div className='market_images_wrapper'>
          <a
            href='https://apps.apple.com/ca/app/ubqt/id6447530154'
            target='_blank'
            rel='noreferrer'
          >
            <img src={AppStoreImage} alt='app store' className='app_store' />
          </a>
          <a
            href='https://play.google.com/store/apps/details?id=com.joinubqt.ubqt&pli=1'
            target='_blank'
            rel='noreferrer'
          >
            <img src={GooglePlayImage} alt='google play' className='app_store' />
          </a>
        </div>
      </div>
    </GetAppWrapper>
  );
};

export default GetTheApp;
