/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import UBQTLogo from 'components/molecules/ubqt-logo';
import TripHeaderV2 from 'components/molecules/trip-header-v2';
import CatchMeCard from 'components/molecules/cards/catch-me-card';
import { colors } from '../../../styles/colors';
import { FormItem, Form, Input, Checkbox, Button } from '../../atoms';
import OrDivider from '../../molecules/or-divider';
import useApiCall from '../../../utils/hooks/useApiCall';
import { guestUserLogin } from '../../../services/settings';
import PageLoader from '../../molecules/page-loader';
import useAuth from '../../../utils/hooks/useAuth';
import { IOS, MACOS, WINDOWS, ANDROID } from '../../../configs/env-vars';
import { extractAddressComponent } from '../../../utils/helpers';

const JoinCatchMeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: ${colors.light_purple};

  .logo {
    display: none;
  }

  .follow_wrapper {
    background-color: ${colors.white};
    border-radius: 20px;
    width: 95%;
  }
  .trip_card_wrapper {
    background-color: ${colors.veryLightGrey};
    padding: 20px 10px;
  }
  .form_section {
    background-color: ${colors.white};
    padding: 20px;
    margin-bottom: 15px;
  }
  .text {
    color: ${colors.black};
    margin-bottom: 15px;
    max-width: 400px;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    line-height: 18px;
  }
  .button_section {
    text-align: center;
    margin-top: 20px;
  }
  .button {
    border-radius: 25px;
    padding: 10px 30px 10px 30px;
  }
  .card_wrapper {
    background-color: ${colors.faded_grey};
    padding: 20px;
  }
  .button_connect {
    background-color: ${colors.white};
    border: 1px solid ${colors.main_color};
    color: ${colors.main_color};
    border-radius: 20px;
  }
  .checkbox_text {
    color: ${colors.greyText};
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
  }
  @media screen and (min-width: 500px) {
    .follow_wrapper {
      margin: 25px;
      width: auto;
    }
    .trip_card_wrapper {
      padding: 20px 90px;
    }
    .logo {
      display: block;
      position: absolute;
      top: 40px;
      left: 50px;
      width: 120px;
      height: 32px;
    }
    .card_wrapper {
      padding: 20px 50px;
    }
    .form_section {
      padding: 20px 50px;
    }
  }
`;

const platformUrls = {
  IOS,
  ANDROID,
  MACOS,
  WINDOWS,
};

const JoinCatchMe = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { catchData, user } = location.state || {};

  const { guestUserSave, guestUser } = useAuth();
  const params = useParams();
  const [state, setState] = useState({
    name: '',
    email: '',
    isChecked: true,
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [, guestUserLoading, guestUserCall] = useApiCall(guestUserLogin);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [searchParams] = useSearchParams();
  const time = searchParams.get('token');
  const slug = searchParams.get('userSlug');
  const avatar = searchParams.get('avatar');

  const { t } = useTranslation();

  const handleChange = allValues => {
    setState({
      ...state,
      ...allValues,
    });
  };

  const handleSubmit = async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('join_catch_me');

    guestUserCall(
      {
        name: state.name,
        email: state.email,
        recaptchaToken: token,
      },
      data => {
        guestUserSave(data);
        notification.success({ message: 'Guest user created successfully' });
        if (catchData && user) {
          navigate(
            `/verify-catch-me/${params.name}?token=${time}&userSlug=${slug}&avatar=${avatar}&guestEmail=${guestUser?.email}`,
            { state: { catchData, user } }
          );
        } else {
          notification.error({ message: 'Meeting or user data is missing.' });
        }
      },
      error => {
        notification.error({ message: error.message });
      }
    );
  };

  useEffect(() => {
    setIsButtonDisabled(!Object.values(state).every(Boolean));
  }, [state]);

  const getPlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      return 'WINDOWS';
    }
    if (/android/i.test(userAgent)) {
      return 'ANDROID';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'IOS';
    }
    if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
      return 'MACOS';
    }
    if (/Win32|Win64|Windows|WinCE/.test(userAgent)) {
      return 'WINDOWS';
    }
    return 'OTHER';
  };

  const platform = getPlatform();

  const href = platformUrls[platform] || '#';

  const country = extractAddressComponent(catchData?.place?.address_components, 'country');
  const city = extractAddressComponent(catchData?.place?.address_components, 'locality');

  if (guestUserLoading) {
    return <PageLoader />;
  }

  return (
    <JoinCatchMeWrapper>
      <UBQTLogo />
      <div className='follow_wrapper'>
        <TripHeaderV2
          title={t('app.followCatchMe')}
          avatarUrl={avatar}
          onClose={() => navigate(`/catch/${slug}?token=${time}&catch_id=${params.name}`)}
        />
        <div className='card_wrapper'>
          {catchData && (
            <CatchMeCard
              isCatchMeJoinCard={true}
              name={catchData?.place?.name}
              city={city}
              country={country}
              address={''}
              startDateTimeUTC={catchData?.startDateTimeUTC}
              endDateTimeUTC={catchData?.endDateTimeUTC}
              walk={catchData?.note}
            />
          )}
        </div>
        <div className='form_section'>
          <div className='text'>
            {t('app.fillFormTrip', { feature: 'catchup' })}
            <br />
            {t('app.calendar')}
          </div>
          <Form
            initialValues={state}
            layout='vertical'
            onFinish={handleSubmit}
            onValuesChange={handleChange}
          >
            <FormItem name='name' rules={[{ required: true, message: t('app.fullNameRequired') }]}>
              <Input placeholder={t('app.fullName')} />
            </FormItem>
            <FormItem
              name='email'
              rules={[
                { required: true, message: t('app.emailRequired') },
                { type: 'email', message: t('app.emailInvalid') },
              ]}
            >
              <Input placeholder={t('app.email')} />
            </FormItem>
            <FormItem name='isChecked' valuePropName='checked'>
              <Checkbox>
                <div className='checkbox_text'>{t('app.acceptContentByEmail')}</div>
              </Checkbox>
            </FormItem>
          </Form>
          <div className='button_section'>
            <Button
              className='button'
              htmlType='submit'
              onClick={handleSubmit}
              disabled={isButtonDisabled}
              style={{
                backgroundColor: isButtonDisabled ? colors.disabledColor : colors.main_color,
                color: isButtonDisabled ? colors.white : colors.white,
              }}
            >
              {t('app.join')}
            </Button>
          </div>
          <OrDivider />
          <div className='button_section'>
            <a href={href}>
              <Button className='button_connect'>{t('app.connectApp')}</Button>
            </a>
          </div>
        </div>
      </div>
    </JoinCatchMeWrapper>
  );
};

export default JoinCatchMe;
